// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-work-template-js": () => import("/opt/build/repo/src/templates/workTemplate.js" /* webpackChunkName: "component---src-templates-work-template-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-studio-js": () => import("/opt/build/repo/src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-what-we-do-js": () => import("/opt/build/repo/src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

